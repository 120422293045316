import React, { useState, useEffect } from 'react'
import { Styled } from './VitrineViagem.styled'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setModalShowRedux } from 'store/modal/actions'
import Modal from 'react-bootstrap/Modal'
import LoginBox from 'containers/LoginBox/LoginBox'
import openExternalLink from 'util/LinkToShowCase'
import axios from 'shared/http'
import jwt from 'jwt-decode'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import pushDataLayerGTM from 'util/pushDataLayerGTM'

const VitrineViagem = ({ Categoria, color, txt, txtColor, showModal }) => {
  const history = useHistory()
  const [itemCategoriaPorto, setItemCategoriaPorto] = useState()
  const [urlAux, setUrlAux] = useState('')
  const [auxIframe, setAuxIframe] = useState(false)
  const [linkCatalogo, setLinkCatalogo] = useState('')
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem('auth-token')
  )
  const [CategoryWithOutPorto, setCategoryWithOutPorto] = useState([])
  useEffect(() => {
    const teste = Categoria[0]
    setItemCategoriaPorto(teste)
    const aux = Categoria?.filter((x) => x.name != 'BeFly')
    setCategoryWithOutPorto(aux)
  }, [])
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1224 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1224, min: 499 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 499, min: 0 },
      items: 1,
    },
  }

  const sendEventGTM = (valueEvent) => {
    let category = 'geral'
    let action = 'clique:menu'
    let label = valueEvent
    pushDataLayerGTM(category, action, label)
  }

  const OfertaViagem = async (item) => {
    if (item.name == 'BeFly') {
      getMarketplaceHomeUrl('https://porto.beflyconecta.com.br/Redirect')
    } else {
      sendEventGTM('Oferta-Viagem')
      history.push(item.url, { param: item })
    }
  }

  async function getMarketplaceHomeUrl(externalUrl) {
    let url = `/catalog/v1/webpremios`
    const urlCatalog = `/catalogo/redireciona?URL=${externalUrl}`
    if (isLoggedIn != null && linkCatalogo === '') {
      debugger
      openExternalLink('befly')
    } else {
      history.push('/Login', { url: urlCatalog })
    }
  }

  return (
    <>
      <Styled.VitrineContent colorBg={color}>
        {auxIframe && <iframe src={urlAux} height={0} width={0}></iframe>}
        <Container>
          <Row>
            <Styled.VitrineTxt txtColor={txtColor}>{txt}</Styled.VitrineTxt>
            {
              <Col xs={12} sm={12} md={3}>
                <Card className="m-2 mb-3 mt-6 card-border ">
                  <Card
                    onClick={() => OfertaViagem(itemCategoriaPorto)}
                    className="m-3 d-flex justify-content-center align-items-center card-border-int"
                    style={{
                      minHeight: '187px',
                      maxHeight: '187px',
                      background: `${itemCategoriaPorto?.color}`,
                    }}
                  >
                    <Card.Img
                      className="img-vitrine"
                      variant="top"
                      src={itemCategoriaPorto?.img}
                    />
                  </Card>
                  <Card.Title className="mb-4">
                    {itemCategoriaPorto?.desc ? (
                      <Styled.TxtDisc>
                        {itemCategoriaPorto?.txtInfo}
                      </Styled.TxtDisc>
                    ) : (
                      <Styled.TxtInfo>
                        {itemCategoriaPorto?.txtInfo}
                      </Styled.TxtInfo>
                    )}
                  </Card.Title>
                </Card>
              </Col>
            }
            <Col xs={12} sm={12} md={9}>
              <Carousel
                responsive={responsive}
                infinite={true}
                removeArrowOnDeviceType={['mobile']}
              >
                {CategoryWithOutPorto.map((itens, index) => {
                  return (
                    <Card className="m-2 mb-5  card-border">
                      <Card
                        key={index}
                        onClick={() => OfertaViagem(itens)}
                        className="m-3 d-flex justify-content-center align-items-center card-border-int"
                        style={{
                          minHeight: '187px',
                          maxHeight: '188px',
                          background: `${itens.color}`,
                        }}
                      >
                        {itens.img.map((item, i) => {
                          return (
                            <Card.Img
                              key={i}
                              className="img-vitrine"
                              variant="top"
                              src={item}
                            />
                          )
                        })}
                      </Card>
                      <Card.Title className="mb-4">
                        {itens.desc ? (
                          <Styled.TxtDisc>{itens.txtInfo}</Styled.TxtDisc>
                        ) : (
                          <Styled.TxtInfo>{itens.txtInfo}</Styled.TxtInfo>
                        )}
                      </Card.Title>
                    </Card>
                  )
                })}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </Styled.VitrineContent>
    </>
  )
}

const dispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, dispatch)(VitrineViagem)

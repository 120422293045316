import styled from 'styled-components'
import { respondToMin } from 'shared/theme'
import { Link } from 'react-router-dom'

const BrandcrumbBox = styled.div`
  background: transparent;
  padding: 12px 24px;
  width: 100%;
  bottom: 10px;
  max-width: 1380px;
`

const Wrap = styled.div`
  width: 100%;
  display: flex;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1500px) {
    margin: 0 0;
  }
`

const imgHome = styled.img`
  margin-right: 5px;
`

const Brandcrumb = styled(Link)`
  font-family: Soleil;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  ${({ href }) =>
    href === '/' &&
    `
    img {
      display: block;
    }
  `}

  ${({ state }) =>
    state === 'visited' &&
    `
    color: #000;

    &:after {
      content: '›';
      color: #1C1C1C;
      padding: 0px 10px;
      font-family: Soleil;
    }    
  `}
  ${({ state }) =>
    state === 'active' &&
    `
    color: #1C1C1C;
  `}

@media (max-width: 768px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const Styled = {
  Wrap,
  BrandcrumbBox,
  imgHome,
  Brandcrumb,
}
